import React from "react"
import Layout from "../../components/layout"
import Head from "../../components/head"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const EcsoPage = () => {
  return (
    <Layout title="13th Congress of the European Council of Skeptical Organisations (ECSO)">
      <Head title="ECSO 2007" description="13th Congress of the European Council of Skeptical Organisations (ECSO)."/>
      <div className="content is-hidden-desktop">
        <p className="is-size-6">
          7-9 Sep 2007<br />
          Davenport Hotel,<br />
          Merrion Square,<br />
          Dublin 2
        </p>
      </div>
      <div className="columns is-desktop">
        <div className="column is-full-mobile is-two-thirds-desktop">
          <div className="content">
            <p>In 2007 the ISS hosted the 13th congress... below is a brief description of the event.</p>
          </div>
          <p className="subtitle is-6">Congress theme:</p>
          <p className="title is-4">The Assault on Science: Constructing a Response</p>
          <div className="content">
            <p>The skeptics movement is comprised of individuals with three primary goals:</p>
            <ul>
              <li>To promote science</li>
              <li>To promote critical thinking</li>
              <li>To challenge extraordinary claims</li>
            </ul>
            <p>As Richard Dawkins has stated:</p>
            <p style={{paddingLeft:"2rem", paddingRight: "2rem"}}><em>"The enlightenment is under threat. So is reason, So is truth. So is science. I am one of those scientists who feel that it is no longer enough just to get on and do science. We have to devote a significant proportion of our time and resources to defending it from deliberate attack from organized ignorance."</em></p>
            <p>This congress aims to do just that.</p>
            <p>Among the Irish speakers are <strong>Prof John Crown</strong> (Consultant Oncologist, St Vincent's University Hospital), <strong>Prof David McConnell</strong> (Genetics Dept. TCD) and <strong>Dr Brian Hughes</strong> (Health Psychologist, NUI Galway).</p>
            <p>Visiting speakers include <strong>Dr Ben Goldacre</strong>, (Guardian UK columnist and medical doctor); <strong>Prof Victor Stenger</strong> (physicist, USA); <strong>Prof Massimo Pigliucci</strong> (evolutionary biologist, USA); <strong>Prof Willem Betz</strong> (medical doctor Belgium), <strong>Prof James Alcock</strong> (social psychologist, Canada), <strong>Prof Chris French</strong> (anomalistic psychologist UK)<sup>*</sup>.</p>
          </div>
          <div className="content is-small">
            <p><sup>*</sup>Chris studies unusual experiences, often those considered paranormal, and attempts to explain them in terms of known physical and psychological factors.</p>
          </div>
          <div className="content">
            <p>Topics to be covered include:</p>
            <ul>
              <li>The assault on scientific rationality</li>
              <li>The assault on medicine</li>
              <li>Understanding belief systems</li>
              <li>Psychological factors in physical health</li>
              <li>Distinguishing science from pseudoscience</li>
              <li>The science vs. religion debate</li>
              <li>Studying prayer</li>
              <li>Public misunderstanding of genetics</li>
              <li>The Genetically Modified Organisms debate</li>
              <li>Battling the paranormal with rationality</li>
              <li>Science and power</li>
              <li>Countering propaganda from complementary and alternative medicine</li>
            </ul>
          </div>
        </div>
        <div className="column is-one-third-desktop is-hidden-touch">
          <div className="box">
            <div className="content">
              <p className="has-text-centered">
                <Image />
              </p>
              <p className="is-size-6">
                <span className="is-size-6">7-9 Sep 2007</span>
              </p>
            </div>
            <div className="content">
              <p>Davenport Hotel,<br />
                 Merrion Square,<br />
                 Dublin 2
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default EcsoPage

const Image = () => {
  const { logo } = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "images/ecso2007.png" }) {
          childImageSharp {
            fixed(height: 72) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `
  )
  return (
    <Img fixed={logo.childImageSharp.fixed} alt="Irish Skeptics Society logo"/>
  )
}